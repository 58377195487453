const axios = require('axios').default;
let baseURL = process.env.NODE_ENV === 'production' ? 'https://dashboard.exposebox.com' : 'https://localhost:443';

class ApiClient {
    async getSubscriptionData(params) {
        console.log('+++++', baseURL);

        return axios.get(`${baseURL}/api/user-lists-subscriptions`, {
            params
        })
            .then(resp => resp.data)
            .catch(err => {
                console.error(err);
            });}

    async updateSubscriptionData(data) {
        console.log('+++++', baseURL);

        return axios.post(`${baseURL}/api/user-lists-subscriptions`,
            {...data})
            .then(resp => resp)
            .catch(err => {
                console.error(err);
            });
    }

    async createUnsubscribedMember(data) {
        console.log('+++++', baseURL);

        return axios.post(`${baseURL}/api/user-lists-subscriptions`,
            {...data, shouldCreateUnsubscribedMember: true})
            .then(resp => resp)
            .catch(err => {
                console.error(err);
            });
    }
}

export default new ApiClient();